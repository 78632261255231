<template>
    <b-overlay :show="loading">
      <b-row>
          <b-col cols="12">
              <b-card-actions :no-actions="true" title="List Barang Aset">
                  
                  <section>
                      <b-button
                          v-if="allowCreate()"
                          variant="primary"
                          class="mb-2 mr-2"
                          @click.prevent="$router.push({ name: 'asset-add' })"
                      >
                          Add
                      </b-button>
                      <b-button
                          v-if="allowDelete() && showMassDelButton"
                          variant="outline-danger"
                          class="mb-2"
                          @click.prevent="confirmMassDelete"
                      >
                          Hapus
                      </b-button>
                  </section>
                <!-- search input -->
  
                <!--  -->
                <data-barang :datas="items" @refreshData="getData" @loading="toggleLoading" />
                <!-- / -->
              </b-card-actions>
          </b-col>
      </b-row>
    </b-overlay>
  </template>
  
  <script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import {
    BOverlay, BRow, BCol,
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
    BButton, BCardText, BTable
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import store from '@/store/index'
  import DataBarang from './components/DataBarang.vue';
  
  export default {
    components: {
        DataBarang,
        BButton,
        BOverlay, BRow,
        BCol,
      BCardActions,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
    },
    data() {
      return {
        loading: false,
        params: {
          order: 'desc'
        },
        items: [],
        showMassDelButton: false,
        status: [{
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
      }
    },
    methods: {
      toggleLoading(val) {
        this.loading = val
      },
      async getData() {
        const params = {order: 'desc'}
        const barangs = await this.$store.dispatch('asset/getBarang', params)
        this.items = barangs.data
        this.totalRows = barangs.total
      }
    },
    async created() {
      this.loading = true
      await this.getData()
      this.loading = false

    },
  }
  </script>